import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, withLatestFrom, mergeMap } from 'rxjs/operators';
import { SET_QUESTION_ANSWER, SET_QUESTION_ANSWER_FULFILLED } from '../constants';
import { submitQuestionAnswer } from '@models/Question';

export const setQuestionAnswer = answer => ({ type: SET_QUESTION_ANSWER, payload: answer });

export const setQuestionAnswerFulfilled = questionId => ({ type: SET_QUESTION_ANSWER_FULFILLED, payload: questionId });

const setQuestionAnswerEpic = (action$, state$) => action$.pipe(
    ofType(SET_QUESTION_ANSWER),
    withLatestFrom(state$),
    mergeMap(([action, state]) => from(submitQuestionAnswer({
        sessionId: state.session.sessionId,
        questionId: state.questions.currentQuestion.questionId,
        answer: action.payload
    })).pipe(map(questionId => setQuestionAnswerFulfilled(questionId))))
);


export default setQuestionAnswerEpic;