import { CANCEL_QUIZ_ANSWER, CANCEL_QUIZ_ANSWER_FULFILLED } from '@redux/quiz/constants';
import { from } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { mapActionSelect } from '@utils/rxjsHelper';
import { getSessionId } from '@redux/session/selectors';
import { submitQuizCancel } from '@models/Quiz';

export const cancelQuizAnswer = reason => ({ type: CANCEL_QUIZ_ANSWER, payload: reason });

export const cancelQuizAnswerFulfilled = quizzesCompleted => ({
    type: CANCEL_QUIZ_ANSWER_FULFILLED,
    payload: quizzesCompleted
});

const cancelQuizAnswerEpic = (action$, state$) => action$.pipe(
    ofType(CANCEL_QUIZ_ANSWER),
    withLatestFrom(state$),
    mapActionSelect(getSessionId),
    mergeMap(([action, sessionId]) => from(submitQuizCancel(sessionId, action.payload)).pipe(
        map(({ quizzesCompleted }) => cancelQuizAnswerFulfilled(quizzesCompleted))
    ))
);

export default cancelQuizAnswerEpic;