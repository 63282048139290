import { ofType } from 'redux-observable';
import { map, withLatestFrom } from 'rxjs/operators';
import { UPDATE_SESSION_STATE, UPDATE_SESSION_STATE_DONE } from '../constants';
import { PROGRESS_STATE_INITIAL, PROGRESS_STATE_INTERVIEW } from '@utils/progress';
import { FETCH_QUESTIONNAIRE_FULFILLED } from '@redux/questionnaire/constants';
import { getSessionState } from '@redux/session/selectors/getSessionState';
import { getQuestionIds } from '@redux/questionnaire/selectors/getQuestionIds';
import { fetchQuestions } from '@redux/questions';
import { mapSelect } from '@utils/rxjsHelper';

export const updateSessionState = sessionState => ({ type: UPDATE_SESSION_STATE, payload: sessionState });

export const updateSessionStateDone = () => ({ type: UPDATE_SESSION_STATE_DONE });

const updateSessionStateEpic = (action$, state$) => action$.pipe(
    ofType(FETCH_QUESTIONNAIRE_FULFILLED, UPDATE_SESSION_STATE),
    withLatestFrom(state$),
    mapSelect(getSessionState, getQuestionIds),
    map(([sessionState, questionIds]) => {
        switch (sessionState) {
            case PROGRESS_STATE_INITIAL:
                return updateSessionStateDone();
            case PROGRESS_STATE_INTERVIEW:
                return fetchQuestions(questionIds);
            default:
                return updateSessionStateDone();
        }
    })
);

export default updateSessionStateEpic;