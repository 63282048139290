export const getCodingTestInfos = state => {
    const { codingTest } = state.questionnaire;
    const infos = {};

    if (codingTest.timeLimit?.days) {
        const { days } = codingTest.timeLimit;
        infos.time = `${ days } day${ days > 1 ? 's' : '' }`;
    }

    return infos;
};