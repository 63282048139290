export const downloadBlob = (blob, filename)  => {
    const blobURL = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = blobURL;
    link.download = filename;

    document.body.appendChild(link);

    link.dispatchEvent(new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
    }));

    document.body.removeChild(link);
}

export const downloadFile = async (url, filename) => {
    const response = await fetch(url);
    const blob = await response.blob();

    downloadBlob(blob, filename);
}