import questions from './questionsReducer';
import fetchQuestionsEpic, { fetchQuestions, fetchQuestionsFulfilled } from './actions/fetchQuestions';
import setQuestionAnswerEpic, { setQuestionAnswer, setQuestionAnswerFulfilled } from './actions/setQuestionAnswer';

export default questions;

export {
    fetchQuestionsEpic,
    fetchQuestions,
    fetchQuestionsFulfilled,
    setQuestionAnswerEpic,
    setQuestionAnswer,
    setQuestionAnswerFulfilled
};