export const FETCH_NEXT_QUESTION = 'FETCH_NEXT_QUESTION';
export const FETCH_NEXT_QUESTION_FULFILLED = 'FETCH_NEXT_QUESTION_FULFILLED';

export const SET_QUIZ_ANSWER = 'SET_QUIZ_ANSWER';
export const SET_QUIZ_ANSWER_FULFILLED = 'SET_QUIZ_ANSWER_FULFILLLED';

export const CANCEL_QUIZ_ANSWER = 'CANCEL_QUIZ_ANSWER';
export const CANCEL_QUIZ_ANSWER_FULFILLED = 'CANCEL_QUIZ_ANSWER_FULFILLED';

export const REASON_SKIPPED = 'skipped';
export const REASON_TIMEOUT = 'timeout';
export const REASON_CHEATED = 'cheated';
export const REASONS = {
    [REASON_SKIPPED]: 'You skipped the last question.',
    [REASON_TIMEOUT]: 'You run out of time.',
    [REASON_CHEATED]: 'You tried cheating by leaving the active question.'
};