import React from 'react';
import introText from './Intro.md';
import { useSelector, useDispatch } from 'react-redux';
import { getSessionInfos } from '@redux/session/selectors/getSessionInfos';
import Prose from '@components/Prose/Prose';
import Panel from '@components/Panel/Panel';
import Button from '@components/Button/Button';
import { fetchNextSessionState } from '@redux/session';
import { useMarkdown, useTextReplace } from '@hooks';

const Intro = () => {
    const dispatch = useDispatch();
    const markdown = useMarkdown(introText);
    const replacements = useSelector(getSessionInfos);
    const text = useTextReplace(markdown, replacements);

    const panelFooter = (
        <div className="text-right">
            <Button label="Start" large={ true } onClick={ () => dispatch(fetchNextSessionState()) }/>
        </div>
    );

    return (
        <Panel footer={ panelFooter }>
            <Prose body={ text }/>
        </Panel>
    );
};

export default Intro;