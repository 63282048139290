import React, { useRef, useState } from 'react';
import classNames from 'classnames';

const FileDrop = ({ onFileChange }) => {
    const fileInputRef = useRef(null);
    const [dragging, setDragging] = useState(0);
    const [file, setFile] = useState(null);

    const preventDefault = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    const onDragEnter = event => {
        preventDefault(event);
        setDragging((val) => val + 1);
    };

    const onDragLeave = event => {
        preventDefault(event);
        setDragging((val) => val - 1);
    };

    const handleFiles = (files) => {
        if (files.length === 0 || files.length > 1) {
            return; // TODO implement error
        }

        setFile(files[0]);
        onFileChange(files[0]);
    };

    const onFileDrop = event => {
        preventDefault(event);
        setDragging(0);
        fileInputRef.current.value = '';
        handleFiles(event.dataTransfer.files);
    };

    const onFileRemove = () => {
        fileInputRef.current.value = '';
        setFile(null);
        onFileChange(null);
    };

    const onFileSelect = event => handleFiles(event.target.files);

    return (
        <>
            <div
                className={ classNames('flex justify-center transition-colors duration-500 items-center h-24 bg-gray-50 rounded-2xl', {
                    'bg-gray-200': dragging > 0
                }) }
                onDrop={ onFileDrop }
                onDragEnter={ onDragEnter }
                onDragLeave={ onDragLeave }
                onDragOver={ preventDefault }>
                { file ? (
                    <>
                        { file.name }
                        <button className="text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-offset-0"
                                onClick={ onFileRemove }>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-2" fill="none"
                                 viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                        </button>
                    </>
                ) : (
                    <>
                        <button className="underline focus:outline-none focus:ring-offset-0"
                                onClick={ () => fileInputRef.current.click() }>Select your file
                        </button>
                        <span>&nbsp;or drop here</span>
                    </>
                ) }
            </div>
            <input className="hidden" onChange={ onFileSelect } type="file" ref={ fileInputRef }/>
        </>
    );
};

export default FileDrop;