import { functions } from '@services/firebase';
import { getQuestionnaireRef } from '@models/Questionnaire';
import { DB_COLLECTION_QUESTIONS } from '@models/constants';

const answerQuestion = functions.httpsCallable('answerQuestion');

export default class Question {
    constructor({ body, type, options = [] }) {
        this.body = body;
        this.type = type;
        this.options = options;
    }
}

export const questionConverter = {
    toFirestore(question) {
        return null;
    },
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return new Question({ ...data });
    }
};

export const getQuestionById = async (questId, questionId) => {
    const doc = await getQuestionnaireRef(questId)
        .collection(DB_COLLECTION_QUESTIONS)
        .doc(questionId)
        .withConverter(questionConverter)
        .get();

    if (!doc.exists) {
        throw new Error('Question not found.');
    }

    return doc.data();
};

// TODO add session token logic later
export const submitQuestionAnswer = ({ sessionId, questionId, answer }) => {
    const result = answerQuestion({ sessionId, questionId, answer }); // TODO not finished
    // return Object.keys(result.data.questResults).find(id => id === questionId);
    return result;
};