import React from 'react';
import completedText from './Completed.md';
import Panel from '@components/Panel/Panel';
import Prose from '@components/Prose/Prose';
import { useMarkdown } from '@hooks';

const Completed = () => {
    const markdown = useMarkdown(completedText);

    return (
        <Panel >
            <Prose body={ markdown }/>
        </Panel>);
};

export default Completed;