import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { withLatestFrom, map, mergeMap } from 'rxjs/operators';
import { mapSelect } from '@utils/rxjsHelper';
import { START_CODING_TEST, START_CODING_TEST_DONE } from '@redux/session/constants';
import { getSessionId } from '@redux/session/selectors/getSessionId';
import { initializeCodingTest } from '@models/Session';

export const startCodingTest = () => ({ type: START_CODING_TEST });

export const startCodingTestDone = startDate => ({ type: START_CODING_TEST_DONE, payload: startDate });

const startCodingTestEpic = (action$, state$) => action$.pipe(
    ofType(START_CODING_TEST),
    withLatestFrom(state$),
    mapSelect(getSessionId),
    mergeMap(sessionId => from(initializeCodingTest(sessionId)).pipe(
        map(({ startDate }) => startCodingTestDone(startDate))
    ))
);

export default startCodingTestEpic;