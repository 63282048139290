import React from 'react';
import marked from 'marked';
import DOMPurify from 'dompurify';

const Prose = ({ body }) =>
    (<div className="prose mb-3"
          dangerouslySetInnerHTML={ {
              __html: DOMPurify.sanitize(marked(body.replace(/\\n/g, '\n')))
          } }
    />);


export default Prose;