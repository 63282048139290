import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ProgressBar = ({ percent }) => {
    const firstStepActive = percent >= 5;
    const secondStepActive = percent >= 50;
    const thirdStepActive = percent >= 95;

    return (
        <div className="relative flex justify-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">

            <div className="absolute z-0 flex items-center w-full max-w-2xl h-11 px-2">
                <div className="overflow-hidden flex w-full h-2 text-xs bg-gray-300 rounded">
                    <div style={ { width: percent + '%' } }
                         className="transition-width duration-500 shadow-none flex flex-col text-center whitespace-nowrap justify-center bg-indigo-500"/>
                </div>
            </div>

            <ul className="relative z-10 flex justify-between space-x-10 w-full max-w-2xl">
                <li className="flex flex-col w-24 space-y-1 items-center">
                    <div className={ classNames('p-2 w-11 rounded-full ring-4 ring-white border-4 ', {
                        'bg-gray-500 border-gray-300': !firstStepActive,
                        'bg-indigo-500 border-indigo-300': firstStepActive
                    }) }>
                        <svg className="w-full text-indigo-50" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd"
                                  d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5
                                  7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
                                  clipRule="evenodd"/>
                        </svg>
                    </div>
                    <span className="text-sm text-gray-500">Interview</span>
                </li>
                <li className="flex flex-col w-24 space-y-1 items-center">
                    <div className={ classNames('p-2 w-11 rounded-full ring-4 ring-white border-4 ', {
                        'bg-gray-500 border-gray-300': !secondStepActive,
                        'bg-indigo-500 border-indigo-300': secondStepActive
                    }) }>
                        <svg className="w-full text-indigo-50" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20" fill="currentColor">
                            <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"/>
                            <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012
                                2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9
                                12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clipRule="evenodd"/>
                        </svg>
                    </div>
                    <span className="text-sm text-gray-500">Quiz</span>
                </li>
                <li className="flex flex-col w-24 space-y-1 items-center">
                    <div className={ classNames('p-2 w-11 rounded-full ring-4 ring-white border-4 ', {
                        'bg-gray-500 border-gray-300': !thirdStepActive,
                        'bg-indigo-500 border-indigo-300': thirdStepActive
                    }) }>
                        <svg className="w-full text-gray-50" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0
                                11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293
                                2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0
                                011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0
                                010-1.414z"
                                  clipRule="evenodd"/>
                        </svg>
                    </div>
                    <span className="text-sm text-gray-500">Coding Test</span>
                </li>
            </ul>

        </div>
    );
};

ProgressBar.propTypes = {
    percent: PropTypes.number
};

ProgressBar.defaultProps = {
    percent: 0
};

export default ProgressBar;