import { ofType } from 'redux-observable';
import { filter, withLatestFrom, map } from 'rxjs/operators';
import { mapSelect } from '@utils/rxjsHelper';
import { getSessionState } from '@redux/session/selectors/getSessionState';
import { isQuestionnaireCompleted } from '@redux/questionnaire/selectors/isQuestionnaireCompleted';
import { SET_QUESTION_ANSWER_FULFILLED } from '@redux/questions/constants';
import { PROGRESS_STATE_INTERVIEW } from '@utils/progress';
import { fetchNextSessionState } from '@redux/session';

const trackQuestionnaireStateEpic = (action$, state$) => action$.pipe(
    ofType(SET_QUESTION_ANSWER_FULFILLED),
    withLatestFrom(state$),
    mapSelect(getSessionState, isQuestionnaireCompleted),
    filter(([sessionState, isCompleted]) => sessionState === PROGRESS_STATE_INTERVIEW && isCompleted),
    map(() => fetchNextSessionState())
);

export default trackQuestionnaireStateEpic;