import { ofType } from 'redux-observable';
import { from, combineLatest, of } from 'rxjs';
import { concatMap, map, mergeMap, withLatestFrom, toArray, filter } from 'rxjs/operators';
import { FETCH_QUESTIONS, FETCH_QUESTIONS_FULFILLED } from '../constants';
import { getCompletedQuestions } from '@redux/questionnaire/selectors/getCompletedQuestions';
import { getQuestionnaireId } from '@redux/session/selectors/getQuestionnaireId';
import { getQuestionById } from '@models/Question';

export const fetchQuestions = questionIds => ({ type: FETCH_QUESTIONS, payload: questionIds });

export const fetchQuestionsFulfilled = questions => ({ type: FETCH_QUESTIONS_FULFILLED, payload: questions });

const fetchQuestionsEpic = (action$, state$) => action$.pipe(
    ofType(FETCH_QUESTIONS),
    withLatestFrom(state$),
    concatMap(([action, state]) => from(action.payload).pipe( // Keep order correct
        filter(questionId => !getCompletedQuestions(state).includes(questionId)), // Don't fetch answered questions
        mergeMap(questionId => combineLatest([ // Pass-through for questionId
            of(questionId),
            from(getQuestionById(getQuestionnaireId(state), questionId))
        ])),
        map(([questionId, question]) => ({ ...question, questionId })),
        toArray()
    )),
    map(questions => fetchQuestionsFulfilled(questions))
);

export default fetchQuestionsEpic;