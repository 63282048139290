import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { withLatestFrom, mergeMap, concatMap } from 'rxjs/operators';
import { mapSelect } from '@utils/rxjsHelper';
import { getSessionId } from '@redux/session/selectors/getSessionId';
import { getNextSessionState } from '@models/Session';
import { updateSessionState } from '@redux/session';
import { FETCH_NEXT_STATE, FETCH_NEXT_STATE_FULFILLED } from '@redux/session/constants';

export const fetchNextSessionState = () => ({ type: FETCH_NEXT_STATE });

export const fetchNextSessionStateFulfilled = () => ({ type: FETCH_NEXT_STATE_FULFILLED });

const fetchNextSessionStateEpic = (action$, state$) => action$.pipe(
    ofType(FETCH_NEXT_STATE),
    withLatestFrom(state$),
    mapSelect(getSessionId),
    mergeMap((sessionId) => from(getNextSessionState(sessionId)).pipe(
        concatMap(({ sessionState }) => [
            updateSessionState(sessionState),
            fetchNextSessionStateFulfilled()
        ])
    ))
);

export default fetchNextSessionStateEpic;