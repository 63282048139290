import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentQuestion } from '@redux/questions/selectors/getCurrentQuestion';
import { setQuestionAnswer } from '@redux/questions';
import Sequencer from '@components/Sequencer/Sequencer';

const Questionnaire = () => {
    const currentQuestion = useSelector(getCurrentQuestion);
    const dispatch = useDispatch();

    const onNextQuestion = (answer) => dispatch(setQuestionAnswer(answer));

    return (
        <>
            { currentQuestion && (
                <Sequencer title="Interview" sequence={ currentQuestion } onNextSequence={ onNextQuestion }/>
            ) }
        </>
    );
};

export default Questionnaire;