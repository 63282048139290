import React, { useState, useEffect } from 'react';
import Radio from './Radio';
import Prose from '../../Prose/Prose';

const RadioUnit = ({ body, options, onStateChange }) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue('');
    }, [options]);

    useEffect(() => onStateChange(value), [onStateChange, value]);

    const onChange = (e) => {
        e.target.checked && setValue(e.target.value);
    };

    return (
        <>
            <Prose body={ body }/>
            { options.map((option, index) =>
                <Radio key={ index }
                       value={ option }
                       name="radio"
                       onChange={ onChange }
                       checked={ option === value }>
                    { option }
                </Radio>) }
        </>
    );
};

export default RadioUnit;