import React, { useEffect, useState } from 'react';
import Checkbox from './Checkbox';
import Prose from '../../Prose/Prose';

const CheckboxUnit = ({ body, options, onStateChange }) => {
    const [values, setValues] = useState([]);

    const addValue = (value) => (values) => [...new Set([...values, value])];
    const removeValue = (value) => (values) => [...values.filter(v => v !== value)];

    const onChange = (e) => {
        const { checked, value } = e.target;
        setValues(checked ? addValue(value) : removeValue(value));
    };

    useEffect(() => {
        setValues([]);
    }, [options]);

    useEffect(() => onStateChange(values), [onStateChange, values]);

    return (
        <>
            <Prose body={ body }/>
            { options.map((option, index) =>
                <Checkbox key={ index }
                          value={ option }
                          name="checkbox"
                          onChange={ onChange }
                          checked={ values.includes(option) }>
                    { option }
                </Checkbox>) }
        </>
    );
};

export default CheckboxUnit;