import { db, functions } from '@services/firebase';
import { DB_COLLECTION_QUESTIONNAIRES } from './constants';

const prepareDownload = functions.httpsCallable('prepareDownload');
const prepareUpload = functions.httpsCallable('prepareUpload');

export default class Questionnaire {
    constructor({ count, description, ownerId, questionsOrder, questionsCount, quiz, quizCount, codingTest }) {
        this.count = count;
        this.description = description;
        this.ownerId = ownerId;
        this.questionsOrder = questionsOrder;
        this.questionsCount = questionsCount;
        this.quiz = quiz;
        this.quizCount = quizCount;
        this.codingTest = codingTest;
    }
}

export const questionnaireConverter = {
    toFirestore(questionnaire) {
        return null;
    },
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return new Questionnaire({ ...data });
    }
};

export const getQuestionnaireRef = (questionnaireId) => {
    return db.collection(DB_COLLECTION_QUESTIONNAIRES).doc(questionnaireId);
};

export const getQuestionnaireById = async (questionnaireId) => {
    const doc = await getQuestionnaireRef(questionnaireId)
        .withConverter(questionnaireConverter)
        .get();

    if (!doc.exists) {
        throw new Error('Questionnaire not found.');
    }

    return doc.data();
};

export const getDownloadUrl = async (sessionId) => {
    const result = await prepareDownload({ sessionId });

    return result.data;
};

export const getUploadUrl = async (sessionId, file) => {
    const { name, type } = file;
    const result = await prepareUpload({ sessionId, file: { name, type } });

    return result.data;
};