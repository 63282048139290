import { ofType } from 'redux-observable';
import { START_UPLOAD, START_UPLOAD_DONE } from '@redux/questionnaire/constants';
import { withLatestFrom, mergeMap, concatMap } from 'rxjs/operators';
import { mapActionSelect } from '@utils/rxjsHelper';
import { getSessionId } from '@redux/session/selectors';
import { from } from 'rxjs';
import { getUploadUrl } from '@models/Questionnaire';
import { uploadFile } from '@utils/upload';
import { fetchNextSessionState } from '@redux/session';

export const startUpload = file => ({ type: START_UPLOAD, payload: file });

export const startUploadDone = () => ({ type: START_UPLOAD_DONE });

const startDownloadEpic = (action$, state$) => action$.pipe(
    ofType(START_UPLOAD),
    withLatestFrom(state$),
    mapActionSelect(getSessionId),
    mergeMap(([action, sessionId]) => from(getUploadUrl(sessionId, action.payload)).pipe(
        mergeMap(({ uploadURL }) => from(uploadFile(uploadURL, action.payload)).pipe(
            concatMap(() => [
                fetchNextSessionState(),
                startUploadDone()
            ])
        ))
    ))
);

export default startDownloadEpic;