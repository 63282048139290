import { map } from 'rxjs/operators';

export function selectLatest() {
    const selector = (fn) => ([_, latest]) => fn(latest);

    if (arguments.length > 1) {
        return (v) => [...arguments].map((fn) => selector(fn)(v));
    }

    return selector(arguments[0]);
}

export function mapSelect() {
    return map(selectLatest(...arguments));
}

export function mapActionSelect() {
    return map(([action, state]) => [action, selectLatest(...arguments)([action, state])])
}