import React from 'react';


const Panel = ({ title, footer, children, corner }) => (
    <div className="bg-white shadow overflow-hidden mx-auto max-w-3xl sm:rounded-lg">
        { (title || corner) && <div className="flex justify-between align-middle px-4 py-5 sm:px-6 border-b border-gray-200">
            { title && <h3 className=" text-lg leading-6 font-medium text-gray-900">
                { title }
            </h3> }
            { corner }
        </div> }
        <div className="px-4 py-5 sm:px-6">
            { children }
        </div>
        { footer && <div className="px-4 py-3 bg-gray-50 sm:px-6">
            { footer }
        </div> }
    </div>
);

export default Panel;