export const PROGRESS_INITIAL = 4;
export const PROGRESS_INTERVIEW = 5; // Will highlight the interview icon of the progress bar
export const PROGRESS_QUIZ = 50;
export const PROGRESS_CODING = 95;
export const PROGRESS_COMPLETED = 100;

export const PROGRESS_STATE_INITIAL = 'INITIAL';
export const PROGRESS_STATE_INTERVIEW = 'INTERVIEW';
export const PROGRESS_STATE_QUIZ = 'QUIZ';
export const PROGRESS_STATE_CODING = 'CODING';
export const PROGRESS_STATE_COMPLETED = 'COMPLETED';

function calculateProgress(progressState = null, [totalCount, completedCount] = [1, 0]) {
    let progressMin, progressMax;

    switch (progressState) {
        case PROGRESS_STATE_INITIAL:
            return PROGRESS_INITIAL;
        case PROGRESS_STATE_INTERVIEW: {
            progressMin = PROGRESS_INTERVIEW;
            progressMax = PROGRESS_QUIZ;
            break;
        }
        case PROGRESS_STATE_QUIZ:
            progressMin = PROGRESS_QUIZ;
            progressMax = PROGRESS_CODING;
            break;
        case PROGRESS_STATE_CODING:
            return PROGRESS_CODING;
        case PROGRESS_STATE_COMPLETED:
            return PROGRESS_COMPLETED;
        default:
            return 0;
    }

    const progressRange = progressMax - progressMin;
    const progressStep = progressRange / totalCount;

    return Math.min(Math.round(progressStep * completedCount) + progressMin, progressMax - 1);
}

export default calculateProgress;