export const FETCH_SESSION = ' FETCH_SESSION';
export const FETCH_SESSION_FULFILLED = 'FETCH_SESSION_FULFILLED';

export const FETCH_NEXT_STATE = 'FETCH_NEXT_STATE';
export const FETCH_NEXT_STATE_FULFILLED = 'FETCH_NEXT_STATE_FULFILLED';

export const UPDATE_SESSION_STATE = 'UPDATE_SESSION_STATE';
export const UPDATE_SESSION_STATE_DONE = 'UPDATE_SESSION_STATE_DONE';

export const UPDATE_SESSION_PROGRESS = 'UPDATE_SESSION_PROGRESS';
export const UPDATE_SESSION_PROGRESS_DONE = 'UPDATE_SESSION_PROGRESS_DONE';

export const START_CODING_TEST = 'START_CODING_TEST';
export const START_CODING_TEST_DONE = 'START_CODING_TEST_DONE';