import { from } from 'rxjs';
import { mergeMap, concatMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { FETCH_SESSION, FETCH_SESSION_FULFILLED } from '../constants';
import { getSessionById } from '@models/Session';
import { fetchQuestionnaire } from '@redux/questionnaire';

export const fetchSession = sessionId => ({ type: FETCH_SESSION, payload: sessionId });

export const fetchSessionFulfilled = payload => ({ type: FETCH_SESSION_FULFILLED, payload });

const fetchSessionEpic = action$ => action$.pipe(
    ofType(FETCH_SESSION),
    mergeMap(action => from(getSessionById(action.payload)).pipe(
        concatMap(({ questId, questResults, codingResults, sessionState, applicantName, companyName }) => [
            fetchSessionFulfilled({ applicantName, companyName, sessionState, codingResults }), // TODO don't fetch when completed
            fetchQuestionnaire(questId, questResults)
        ])
    ))
);

export default fetchSessionEpic;