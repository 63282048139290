import React from 'react';
import { useSelector } from 'react-redux';
import { getSessionInfos } from '@redux/session/selectors/getSessionInfos';
import codingText from './CodingTest.md';
import Button from '@components/Button/Button';
import Panel from '@components/Panel/Panel';
import Prose from '@components/Prose/Prose';
import { getCodingTestInfos } from '@redux/questionnaire/selectors/getCodingTestInfos';
import { useMarkdown, useTextReplace } from '@hooks';

const CodingTest = ({ onStartButtonClick }) => {
    const markdown = useMarkdown(codingText);
    const sessionInfos = useSelector(getSessionInfos);
    const codingInfos = useSelector(getCodingTestInfos);
    const text = useTextReplace(markdown, [sessionInfos, codingInfos]);

    const panelFooter = (
        <div className="text-right">
            <Button label="Start" large={ true } onClick={ onStartButtonClick }/>
        </div>
    );

    return (
        <Panel footer={ panelFooter }>
            <Prose body={ text }/>
        </Panel>);
};

export default CodingTest;