import { ofType } from 'redux-observable';
import { START_DOWNLOAD, START_DOWNLOAD_DONE } from '@redux/questionnaire/constants';
import { withLatestFrom, mergeMap, map } from 'rxjs/operators';
import { mapSelect } from '@utils/rxjsHelper';
import { getSessionId } from '@redux/session/selectors';
import { from } from 'rxjs';
import { getDownloadUrl } from '@models/Questionnaire';
import { downloadFile } from '@utils/download';

export const startDownload = () => ({ type: START_DOWNLOAD });

export const startDownloadDone = downloadsCount => ({ type: START_DOWNLOAD_DONE, payload: downloadsCount });

const startDownloadEpic = (action$, state$) => action$.pipe(
    ofType(START_DOWNLOAD),
    withLatestFrom(state$),
    mapSelect(getSessionId),
    mergeMap(sessionId => from(getDownloadUrl(sessionId)).pipe(
        mergeMap(({ filename, downloadURL, downloadsCount }) => from(downloadFile(downloadURL, filename)).pipe(
            map(() => startDownloadDone(downloadsCount))
        ))
    ))
);

export default startDownloadEpic;