import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './services/firebase';

import './index.css';
import App from './App';
import Session from './components/Session/Session';
import configureStore from './redux/configureStore';

const store = configureStore();

ReactDOM.render(
    <Provider store={ store }>
        <Router>
            <Switch>
                <Route exact path="/" component={ App }/>
                <Route path="/session/:sessionId" component={ Session }/>
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root')
);
