import React, { useState, useEffect } from 'react';

import Panel from '../Panel/Panel';
import Button from '../Button/Button';
import Unit from '../units';

const Sequencer = ({ title, sequence, onNextSequence, nextButtonLabel, timer }) => {
    const [value, setValue] = useState(null);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        switch (sequence?.type) {
            case 'radio':
                setDisabled(value === '');
                break;
            case 'prompt':
                setDisabled(value === null);
                break;
            default:
                setDisabled(false);
        }
    }, [sequence, value]);

    const panelFooter = (
        <div className="text-right">
            <Button label={ nextButtonLabel }
                    large={ true }
                    onClick={ () => onNextSequence(value) }
                    disabled={ disabled }/>
        </div>
    );

    const panelTimer = (
        <div className="flex text-gray-400 text-sm">
            <svg xmlns="http://www.w3.org/2000/svg" className="self-center h-5 w-5" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
            </svg>
            <div className="font-mono inline-block w-8 text-center self-center ml-1">
                { Math.floor(timer / 60) }:{ String(timer % 60).padStart(2, '0') }
            </div>
        </div>
    );

    return (
        <Panel title={ title } footer={ panelFooter } corner={ !!timer && panelTimer }>
            <Unit onStateChange={ setValue } { ...sequence }/>
        </Panel>
    );
};

Sequencer.defaultProps = {
    nextButtonLabel: 'Next'
};

export default Sequencer;