import { FETCH_QUESTIONS_FULFILLED, SET_QUESTION_ANSWER_FULFILLED } from './constants';

const initialState = {
    questions: [],
    currentQuestion: null,
};

function questions(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_QUESTIONS_FULFILLED:
            return {
                ...state,
                currentQuestion: { ...payload[0] },
                questions: [...payload.slice(1)]
            };
        case SET_QUESTION_ANSWER_FULFILLED:
            return {
                ...state,
                currentQuestion: { ...state.questions[0] },
                questions: [...state.questions.slice(1)]
            };
        default:
            return state;
    }
}

export default questions;