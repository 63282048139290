import { useEffect, useState } from 'react';

const useMarkdown = (file) => { // TODO move marked into here
    const [markdown, setMarkdown] = useState('');

    useEffect(() => {
        const fetchText = async () => {
            const response = await fetch(file);
            setMarkdown(await response.text());
        };
        fetchText().catch((error) => {
            console.log(error); // TODO add error handling
        });
    }, [file]);

    return markdown;
};

export default useMarkdown;