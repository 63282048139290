import React, { useEffect, useState } from 'react';
import Button from '../../Button/Button';
import Prose from '../../Prose/Prose';

const PromptUnit = ({ body, onStateChange }) => {
    const [value, setValue] = useState(null);

    useEffect(() => onStateChange(value), [onStateChange, value]);

    return (
        <>
            <Prose body={ body }/>
            <div className="flex justify-center gap-2">
                <Button label="No"
                        large={ true }
                        outlined={ true }
                        active={ value === false }
                        onClick={ () => setValue(false) }/>
                <Button label="Yes"
                        large={ true }
                        outlined={ true }
                        active={ value === true }
                        onClick={ () => setValue(true) }/>
            </div>
        </>
    );
};

export default PromptUnit;