export const getCodingTestDeadline = state => {
    const startDate = new Date(state.session.codingResults?.startDate || Date.now());
    const timeLimitDays = state.questionnaire.codingTest.timeLimit?.days || 0;

    const deadlineDate = new Date(startDate.getTime());
    deadlineDate.setDate(deadlineDate.getDate() + timeLimitDays);

    const difference = deadlineDate.getTime() - Date.now();
    const days = Math.ceil(difference / (1000 * 3600 * 24));

    return `${ days } days`;
};