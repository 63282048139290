import React from 'react';

const Container = ({ children }) => (
    <div className="container m-auto py-6 sm:px-6 lg:px-8">
        <div className="mx-auto px-4 py-6 sm:px-0">
            { children }
        </div>
    </div>
);

export default Container;