import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { FETCH_QUESTIONNAIRE, FETCH_QUESTIONNAIRE_FULFILLED } from '../constants';
import { getQuestionnaireById } from '@models/Questionnaire';

export const fetchQuestionnaire = (questId, questResults) => ({
    type: FETCH_QUESTIONNAIRE,
    payload: { questId, questResults }
});

export const fetchQuestionnaireFulfilled = payload => ({ type: FETCH_QUESTIONNAIRE_FULFILLED, payload });

const fetchQuestionnaireEpic = action$ => action$.pipe(
    ofType(FETCH_QUESTIONNAIRE),
    mergeMap(action => from(getQuestionnaireById(action.payload.questId)).pipe(
        map(({ description, questionsOrder, questionsCount, quizCount, codingTest }) =>
            fetchQuestionnaireFulfilled({ description, questionsOrder, questionsCount, quizCount, codingTest })
        )
    ))
);

export default fetchQuestionnaireEpic;