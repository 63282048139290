import { combineEpics } from 'redux-observable';
import { catchError } from 'rxjs/operators';
import { combineReducers } from 'redux';
import session, {
    fetchNextSessionStateEpic,
    fetchSessionEpic,
    startCodingTestEpic,
    updateSessionProgressEpic,
    updateSessionStateEpic
} from './session';
import questionnaire, {
    fetchQuestionnaireEpic,
    startDownloadEpic,
    startUploadEpic,
    trackQuestionnaireStateEpic
} from './questionnaire';
import questions, { fetchQuestionsEpic, setQuestionAnswerEpic } from './questions';
import quiz, {
    cancelQuizAnswerEpic,
    fetchNextQuestionEpic,
    setQuizAnswerEpic,
    trackQuizStateEpic
} from '@redux/quiz';
import status from '@redux/status';

const epics = [
    fetchSessionEpic,
    fetchQuestionnaireEpic,
    updateSessionStateEpic,
    fetchQuestionsEpic,
    setQuestionAnswerEpic,
    fetchNextSessionStateEpic,
    updateSessionProgressEpic,
    trackQuestionnaireStateEpic,
    fetchNextQuestionEpic,
    setQuizAnswerEpic,
    trackQuizStateEpic,
    startCodingTestEpic,
    startDownloadEpic,
    startUploadEpic,
    cancelQuizAnswerEpic
];

export const rootEpic = (action$, store$, dependencies) =>
    combineEpics(...epics)(action$, store$, dependencies).pipe(
        catchError((error, source) => {
            console.error(error);
            return source;
        })
    );

export const rootReducer = combineReducers({
    session,
    questionnaire,
    questions,
    quiz,
    status
});