import { ofType } from 'redux-observable';
import { withLatestFrom, map } from 'rxjs/operators';
import { FETCH_QUESTIONNAIRE_FULFILLED } from '@redux/questionnaire/constants';
import { mapSelect } from '@utils/rxjsHelper';
import { getSessionState } from '@redux/session/selectors/getSessionState';
import {
    FETCH_NEXT_STATE_FULFILLED,
    UPDATE_SESSION_PROGRESS,
    UPDATE_SESSION_PROGRESS_DONE
} from '@redux/session/constants';
import calculateProgress, { PROGRESS_STATE_INTERVIEW, PROGRESS_STATE_QUIZ } from '@utils/progress';
import { getQuestionnaireProgress } from '@redux/questionnaire/selectors/getQuestionnaireProgress';
import { FETCH_QUESTIONS_FULFILLED, SET_QUESTION_ANSWER_FULFILLED } from '@redux/questions/constants';
import { SET_QUIZ_ANSWER_FULFILLED } from '@redux/quiz/constants';
import { getQuizProgress } from '@redux/quiz/selectors/getQuizProgress';

export const updateSessionProgress = () => ({ type: UPDATE_SESSION_PROGRESS });

export const updateSessionProgressDone = progress => ({ type: UPDATE_SESSION_PROGRESS_DONE, payload: progress });

const updateSessionProgressEpic = (action$, state$) => action$.pipe(
    ofType(FETCH_QUESTIONNAIRE_FULFILLED,
        FETCH_QUESTIONS_FULFILLED,
        SET_QUESTION_ANSWER_FULFILLED,
        FETCH_NEXT_STATE_FULFILLED,
        SET_QUIZ_ANSWER_FULFILLED),
    withLatestFrom(state$),
    mapSelect(getSessionState, getQuestionnaireProgress, getQuizProgress),
    map(([sessionState, questionnaireProgress, quizProgress]) => {
        switch (sessionState) {
            case PROGRESS_STATE_INTERVIEW:
                return updateSessionProgressDone(calculateProgress(sessionState, questionnaireProgress));
            case PROGRESS_STATE_QUIZ:
                return updateSessionProgressDone(calculateProgress(sessionState, quizProgress));
            default:
                return updateSessionProgressDone(calculateProgress(sessionState));
        }
    })
);

export default updateSessionProgressEpic;