import {
    CANCEL_QUIZ_ANSWER,
    CANCEL_QUIZ_ANSWER_FULFILLED,
    FETCH_NEXT_QUESTION,
    FETCH_NEXT_QUESTION_FULFILLED, REASONS,
    SET_QUIZ_ANSWER_FULFILLED
} from '@redux/quiz/constants';
import { FETCH_QUESTIONNAIRE_FULFILLED } from '@redux/questionnaire/constants';

const initialState = {
    started: false,
    isRunning: false,
    currentQuiz: null,
    quizCount: 1,
    quizzesCompleted: 0,
    cancelReason: ''
};

function quiz(state = initialState, { type, payload }) {
    switch (type) {
        case CANCEL_QUIZ_ANSWER:
            return {
                ...state,
                cancelReason: REASONS[payload]
            }
        case FETCH_NEXT_QUESTION:
            return {
                ...state,
                cancelReason: ''
            };
        case FETCH_QUESTIONNAIRE_FULFILLED:
            return {
                ...state,
                quizCount: payload.quizCount
            };
        case FETCH_NEXT_QUESTION_FULFILLED:
            return {
                ...state,
                currentQuiz: payload,
                started: true, // should be set when the question is successfully loaded
                isRunning: true
            };
        case CANCEL_QUIZ_ANSWER_FULFILLED:
        case SET_QUIZ_ANSWER_FULFILLED:
            return {
                ...state,
                quizzesCompleted: payload,
                isRunning: false
            };
        default:
            return state;
    }
}

export default quiz;