import quiz from './quizReducer';
import fetchNextQuestionEpic, { fetchNextQuestion, fetchNextQuestionFulfilled } from './actions/fetchNextQuestion';
import setQuizAnswerEpic, { setQuizAnswer, setQuizAnswerFulfilled } from './actions/setQuizAnswer';
import trackQuizStateEpic from './actions/trackQuizState';
import cancelQuizAnswerEpic, { cancelQuizAnswer, cancelQuizAnswerFulfilled } from './actions/cancelQuizAnswer';

export default quiz;

export {
    fetchNextQuestionEpic,
    fetchNextQuestion,
    fetchNextQuestionFulfilled,
    setQuizAnswerEpic,
    setQuizAnswer,
    setQuizAnswerFulfilled,
    trackQuizStateEpic,
    cancelQuizAnswerEpic,
    cancelQuizAnswer,
    cancelQuizAnswerFulfilled
};