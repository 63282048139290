import { FETCH_QUESTIONNAIRE, FETCH_QUESTIONNAIRE_FULFILLED } from './constants';
import { SET_QUESTION_ANSWER_FULFILLED } from '@redux/questions/constants';

const initialState = {
    questId: '',
    description: '',
    questionsCompleted: [],
    questionsOrder: [],
    questionsCount: 0,
    codingTest: {
        timeLimit: null
    }

};

function questionnaire(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_QUESTIONNAIRE:
            return {
                ...state,
                questId: payload.questId,
                questionsCompleted: Object.keys(payload.questResults)
            };
        case FETCH_QUESTIONNAIRE_FULFILLED:
            return {
                ...state,
                description: payload.description,
                questionsOrder: payload.questionsOrder,
                questionsCount: payload.questionsCount,
                codingTest: payload.codingTest
            };
        case SET_QUESTION_ANSWER_FULFILLED:
            return {
                ...state,
                questionsCompleted: [...state.questionsCompleted, payload]
            };
        default:
            return state;
    }
}

export default questionnaire;