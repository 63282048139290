import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
// import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAqUAhKNwaDIcfAsJaSA7ggXB4Gm2RPoh0",
    authDomain: "interbee-io.firebaseapp.com",
    projectId: "interbee-io",
    storageBucket: "interbee-io.appspot.com",
    messagingSenderId: "551010535553",
    appId: "1:551010535553:web:609bab62497dc69bcce580"
};

const app = firebase.initializeApp(firebaseConfig);

export default app;

const db = app.firestore();
const functions = app.functions('europe-west1');
// const storage = app.storage();

// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
    db.useEmulator('localhost', 8080);
    functions.useEmulator('localhost', 5001);
    // storage.useEmulator('localhost', 9199);
}

export { db, functions };
