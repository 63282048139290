import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from '@headlessui/react';

import Panel from '@components/Panel/Panel';
import { CodingTest } from '@components/stories';
import { startCodingTest } from '@redux/session';
import { getCodingTestDeadline, hasStartedCodingTest } from '@redux/session/selectors';
import Button from '@components/Button/Button';
import { startDownload, startUpload } from '@redux/questionnaire';
import { getDownloadsCount } from '@redux/session/selectors';
import FileDrop from '@components/FileDrop/FileDrop';

const Coding = () => {
    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const hasStarted = useSelector(hasStartedCodingTest);
    const deadline = useSelector(getCodingTestDeadline);
    const downloads = useSelector(getDownloadsCount);

    const panelFooter = (
        <div className="text-right">
            <Button label="Upload" large={ true }
                    onClick={ () => dispatch(startUpload(file)) }
                    disabled={ file === null }/>
        </div>
    );

    return (
        <>
            { hasStarted ?
                <Panel title="Coding Test" footer={ downloads > 0 && panelFooter }>
                    <div className="prose max-w-full">
                        <p className="text-center">Please download the following file and follow the instructions:</p>
                        <p className="text-center">
                            <Button onClick={ () => dispatch(startDownload()) }>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                     viewBox="0 0 24 24"
                                     stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
                                </svg>
                                <span className="ml-2">Download</span>
                            </Button>
                        </p>
                        <p className="text-center">
                            You have <strong>{ deadline }</strong> left until upload due date.
                        </p>
                        <Transition show={ downloads > 0 }
                                    enter="transition-all ease-out duration-1000"
                                    enterFrom="max-h-0 opacity-0"
                                    enterTo="max-h-96 opacity-100">
                            <hr/>
                            <FileDrop onFileChange={ file => setFile(file) }/>
                            <hr className="!mb-0"/>
                        </Transition>
                    </div>
                </Panel> :
                <CodingTest onStartButtonClick={ () => dispatch(startCodingTest()) }/> }
        </>
    );
};

export default Coding;