import React from 'react';
import instructionsText from './Instructions.md';
import Button from '@components/Button/Button';
import Panel from '@components/Panel/Panel';
import Prose from '@components/Prose/Prose';
import { useMarkdown } from '@hooks';

const Instructions = ({ onNextButtonClick }) => {
    const markdown = useMarkdown(instructionsText);

    const panelFooter = (
        <div className="text-right">
            <Button label="Continue" large={ true } onClick={ onNextButtonClick }/>
        </div>
    );

    return (
        <Panel footer={ panelFooter }>
            <Prose body={ markdown }/>
        </Panel>);
};

export default Instructions;