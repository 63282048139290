import { useEffect, useState } from 'react';

const useTextReplace = (text, replacements) => {
    const [replacedText, setReplacedText] = useState('');

    useEffect(() => {
        const replacementMap = [];
        if (Array.isArray(replacements)) {
            for (let replacement of replacements) {
                replacementMap.push(...Object.entries(replacement));
            }
        } else {
            replacementMap.push(...Object.entries(replacements));
        }

        const formattedText = replacementMap
            .reduce((formattedText, [key, value]) =>
                formattedText.replaceAll(`{${ key }}`, value), text);
        setReplacedText(formattedText);
    }, [replacements, text]);

    return replacedText;
};

export default useTextReplace;