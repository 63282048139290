import { SET_QUIZ_ANSWER, SET_QUIZ_ANSWER_FULFILLED } from '../constants';
import { ofType } from 'redux-observable';
import { withLatestFrom, mergeMap, map } from 'rxjs/operators';
import { from } from 'rxjs';
import { submitQuizAnswer } from '@models/Quiz';
import { mapActionSelect } from '@utils/rxjsHelper';
import { getSessionId } from '@redux/session/selectors';
import { getCurrentQuiz } from '@redux/quiz/selectors';

export const setQuizAnswer = answer => ({ type: SET_QUIZ_ANSWER, payload: answer });

export const setQuizAnswerFulfilled = quizzesCompleted => ({
    type: SET_QUIZ_ANSWER_FULFILLED,
    payload: quizzesCompleted
});

const setQuizAnswerEpic = (action$, state$) => action$.pipe(
    ofType(SET_QUIZ_ANSWER),
    withLatestFrom(state$),
    mapActionSelect(getSessionId, getCurrentQuiz),
    mergeMap(([action, [sessionId, currentQuiz]]) =>
        from(submitQuizAnswer(sessionId, currentQuiz.quizKey, action.payload)).pipe(
            map(({ quizzesCompleted }) => setQuizAnswerFulfilled(quizzesCompleted))
        )
    )
);

export default setQuizAnswerEpic;