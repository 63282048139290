import React from 'react';
import { CheckboxUnit, PromptUnit, RadioUnit, TextUnit } from '../index';

const Unit = ({ type, ...args }) => {
    switch (type) {
        case 'text':
            return <TextUnit { ...args }/>;
        case 'radio':
            return <RadioUnit { ...args }/>;
        case 'checkbox':
            return <CheckboxUnit { ...args }/>;
        case 'prompt':
            return <PromptUnit { ...args }/>;
        default:
            return null;
    }
};

export default Unit;