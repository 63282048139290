import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchSession } from '@redux/session';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from '@headlessui/react';

import {
    PROGRESS_STATE_CODING,
    PROGRESS_STATE_COMPLETED,
    PROGRESS_STATE_INITIAL,
    PROGRESS_STATE_INTERVIEW,
    PROGRESS_STATE_QUIZ
} from '@utils/progress';
import Container from '@components/Container/Container';
import ProgressBar from '@components/ProgressBar/ProgressBar';
import { Intro, Completed } from '@components/stories';
import Questionnaire from '@components/Questionnaire/Questionnaire';
import Quiz from '@components/Quiz/Quiz';
import Coding from '@components/Coding/Coding';
import { getSessionProgress, getSessionState } from '@redux/session/selectors';
import { isLoading } from '@redux/status/selectors';

const Session = () => {
    const { sessionId } = useParams();
    const dispatch = useDispatch();
    const sessionState = useSelector(getSessionState);
    const sessionProgress = useSelector(getSessionProgress);
    const loading = useSelector(isLoading);

    useEffect(() => {
        dispatch(fetchSession(sessionId));
    }, [dispatch, sessionId]);

    const renderView = (state) => {
        switch (state) {
            case PROGRESS_STATE_INITIAL:
                return <Intro/>;
            case PROGRESS_STATE_INTERVIEW:
                return <Questionnaire/>;
            case PROGRESS_STATE_QUIZ:
                return <Quiz/>;
            case PROGRESS_STATE_CODING:
                return <Coding/>;
            case PROGRESS_STATE_COMPLETED:
                return <Completed/>;
            default:
                return null;
        }
    };

    return (
        <main className="flex flex-col w-screen h-screen">
            <header className="w-screen bg-white shadow">
                <ProgressBar percent={ sessionProgress }/>
            </header>
            <Container>
                <Transition appear={ true }
                            show={ !loading }
                            enter="transition ease-bounce duration-300"
                            enterFrom="transform-gpu scale-50 opacity-0"
                            enterTo="transform-gpu scale-100 opacity-100"
                            leave="transition ease-in duration-150"
                            leaveFrom="transform-gpu scale-100 opacity-50"
                            leaveTo="transform-gpu scale-125 opacity-0">
                    { renderView(sessionState) }
                </Transition>
            </Container>
        </main>
    );
};

export default Session;