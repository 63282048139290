import { useEffect, useState } from 'react';
import { timer } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

const useCountDown = (isActive, onFinished) => {
    const [seconds, setSeconds] = useState(0);

    const resetSeconds = () => setSeconds(0);

    useEffect(() => {
        const timer$ = timer(1000, 1000).pipe(
            filter(() => isActive),
            take(seconds.valueOf()),
            tap(() => {
                if (seconds === 1) {
                    setSeconds(0);
                    onFinished && onFinished();
                }
                setSeconds(seconds - 1);
            })
        );

        const subscription = timer$.subscribe();

        return () => subscription.unsubscribe();
    }, [seconds, setSeconds, onFinished, isActive]);

    return [seconds, setSeconds, resetSeconds];
};

export default useCountDown;