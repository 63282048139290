import { ofType } from 'redux-observable';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { from } from 'rxjs';
import { getNextQuizQuestion } from '@models/Quiz';
import { FETCH_NEXT_QUESTION, FETCH_NEXT_QUESTION_FULFILLED } from '@redux/quiz/constants';
import { mapSelect } from '@utils/rxjsHelper';
import { getSessionId } from '@redux/session/selectors/getSessionId';

export const fetchNextQuestion = () => ({ type: FETCH_NEXT_QUESTION });

export const fetchNextQuestionFulfilled = quiz => ({ type: FETCH_NEXT_QUESTION_FULFILLED, payload: quiz });

const fetchNextQuestionEpic = (action$, state$) => action$.pipe(
    ofType(FETCH_NEXT_QUESTION),
    withLatestFrom(state$),
    mapSelect(getSessionId),
    mergeMap(sessionId => from(getNextQuizQuestion(sessionId)).pipe(
        map((quiz) => fetchNextQuestionFulfilled(quiz))
    ))
);

export default fetchNextQuestionEpic;