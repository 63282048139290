import { db, functions } from '@services/firebase';
import { DB_COLLECTION_SESSIONS } from './constants';

const nextState = functions.httpsCallable('nextState');
const startTest = functions.httpsCallable('startTest');

export default class Session {
    constructor({
                    applicantId,
                    applicantName,
                    companyName,
                    questId,
                    questResults,
                    quizResults,
                    codingResults,
                    sessionState
                }) {
        this.applicantId = applicantId;
        this.applicantName = applicantName;
        this.companyName = companyName;
        this.questId = questId;
        this.questResults = questResults;
        this.quizResults = quizResults;
        this.sessionState = sessionState;
        this.setCodingResults(codingResults);
    }

    setCodingResults(codingResults) {
        if (codingResults.hasOwnProperty('startDate')) {
            codingResults.startDate = new Date(codingResults.startDate.toMillis());
        }
        this.codingResults = codingResults;
    }
}

export const sessionConverter = {
    toFirestore(session) {
        return null;
    },
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return new Session({ ...data });
    }
};

export const getSessionById = async (sessionId) => {
    const doc = await db
        .collection(DB_COLLECTION_SESSIONS)
        .doc(sessionId)
        .withConverter(sessionConverter)
        .get();

    if (!doc.exists) {
        throw new Error('Session not found.');
    }

    return doc.data();
};

export const getNextSessionState = async (sessionId) => {
    const result = await nextState({ sessionId });

    return result.data;
};

export const initializeCodingTest = async (sessionId) => {
    const result = await startTest({ sessionId });

    return result.data;
};