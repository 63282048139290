import {
    FETCH_SESSION,
    FETCH_SESSION_FULFILLED, START_CODING_TEST_DONE,
    UPDATE_SESSION_PROGRESS_DONE,
    UPDATE_SESSION_STATE
} from './constants';
import { START_DOWNLOAD_DONE } from '@redux/questionnaire/constants';

const initialState = {
    sessionId: '',
    applicantName: {
        firstName: '',
        lastName: ''
    },
    companyName: '',
    sessionState: '',
    sessionProgress: 0,
    codingResults: {
        downloadsCount: 0
    }
};

function session(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_SESSION:
            return {
                ...state,
                sessionId: payload
            };
        case FETCH_SESSION_FULFILLED:
            return {
                ...state,
                applicantName: { ...payload.applicantName },
                companyName: payload.companyName,
                sessionState: payload.sessionState,
                codingResults: payload.codingResults
            };
        case UPDATE_SESSION_STATE:
            return {
                ...state,
                sessionState: payload
            };
        case UPDATE_SESSION_PROGRESS_DONE:
            return {
                ...state,
                sessionProgress: payload
            };
        case START_CODING_TEST_DONE:
            return {
                ...state,
                codingResults: {
                    ...state.codingResults,
                    startDate: payload
                }
            };
        case START_DOWNLOAD_DONE:
            return {
                ...state,
                codingResults: {
                    ...state.codingResults,
                    downloadsCount: payload
                }
            };
        default:
            return state;
    }
}

export default session;