import {
    FETCH_NEXT_STATE, FETCH_NEXT_STATE_FULFILLED,
    FETCH_SESSION,
    START_CODING_TEST,
    START_CODING_TEST_DONE
} from '@redux/session/constants';
import {
    FETCH_QUESTIONNAIRE_FULFILLED,
    START_UPLOAD,
    START_UPLOAD_DONE
} from '@redux/questionnaire/constants';
import {
    FETCH_QUESTIONS,
    FETCH_QUESTIONS_FULFILLED,
    SET_QUESTION_ANSWER,
    SET_QUESTION_ANSWER_FULFILLED
} from '@redux/questions/constants';
import {
    CANCEL_QUIZ_ANSWER, CANCEL_QUIZ_ANSWER_FULFILLED,
    FETCH_NEXT_QUESTION,
    FETCH_NEXT_QUESTION_FULFILLED,
    SET_QUIZ_ANSWER,
    SET_QUIZ_ANSWER_FULFILLED
} from '@redux/quiz/constants';

const initialState = {
    loading: true
};

// TODO quiz initial startup animation issues
// TODO coding test animation issues

function status(state = initialState, { type, payload }) {
    switch (type) {
        case START_UPLOAD:
        case START_CODING_TEST:
        case CANCEL_QUIZ_ANSWER:
        case SET_QUIZ_ANSWER:
        case FETCH_NEXT_QUESTION:
        case SET_QUESTION_ANSWER:
        case FETCH_QUESTIONS:
        // case FETCH_NEXT_STATE:
        case FETCH_SESSION:
            return {
                ...state,
                loading: true
            };
        case START_UPLOAD_DONE:
        case START_CODING_TEST_DONE:
        case CANCEL_QUIZ_ANSWER_FULFILLED:
        case SET_QUIZ_ANSWER_FULFILLED:
        case FETCH_NEXT_QUESTION_FULFILLED:
        case SET_QUESTION_ANSWER_FULFILLED:
        case FETCH_QUESTIONS_FULFILLED:
        // case FETCH_NEXT_STATE_FULFILLED:
        case FETCH_QUESTIONNAIRE_FULFILLED:
            return {
                loading: false
            };
        default:
            return state;
    }
}

export default status;