import { functions } from '@services/firebase';

const nextQuiz = functions.httpsCallable('nextQuiz');
const answerQuiz = functions.httpsCallable('answerQuiz');
const cancelQuiz = functions.httpsCallable('cancelQuiz');

export default class Quiz {
    constructor({ quizKey, question, type, options }) {
        this.quizKey = quizKey;
        this.body = question;
        this.type = type;
        this.options = options;
    }
}

export const quizConverter = {
    toFirestore(quiz) {
        return null;
    },
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return new Quiz({ ...data });
    }
};

export const getNextQuizQuestion = async (sessionId) => {
    const result = await nextQuiz({ sessionId });

    return new Quiz(result.data);
};

export const submitQuizAnswer = async (sessionId, quizKey, answer) => {
    const result = await answerQuiz({ sessionId, quizKey, answer });

    return result.data;
};

export const submitQuizCancel = async (sessionId, reason) => {
    const result = await cancelQuiz({ sessionId, reason });

    return result.data;
};