import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sequencer from '@components/Sequencer/Sequencer';
import { fetchNextQuestion, setQuizAnswer, cancelQuizAnswer } from '@redux/quiz';
import { useCountDown } from '@hooks';
import { getCurrentQuiz, hasQuizStarted, isQuizRunning } from '@redux/quiz/selectors';
import { Instructions } from '@components/stories';
import { REASON_TIMEOUT, REASON_CHEATED } from '@redux/quiz/constants';

const intermediateSequence = {
    type: 'text',
    body: 'Click on **Next** when you are ready.'
};

const Quiz = () => {
    const dispatch = useDispatch();
    const hasStarted = useSelector(hasQuizStarted);
    const isRunning = useSelector(isQuizRunning);
    const currentQuiz = useSelector(getCurrentQuiz);
    const [timer, startTimer, cancelTimer] = useCountDown(isRunning, () => dispatch(cancelQuizAnswer(REASON_TIMEOUT)));

    useEffect(() => {
        const cancel = () => isRunning && dispatch(cancelQuizAnswer(REASON_CHEATED)) && cancelTimer();
        const cancelHidden = () => document.hidden && cancel();

        // TODO maybe the first one is not necessary
        // detect if user chanegd tab
        document.addEventListener('visibilitychange', cancelHidden);
        // detect if user changed application
        window.addEventListener('blur', cancel);

        return () => document.removeEventListener('visibilitychange', cancelHidden)
            || window.removeEventListener('blur', cancel);
    }, [isRunning, dispatch, cancelTimer]);

    const onNextButtonClick = () => {
        dispatch(fetchNextQuestion());
        startTimer(20);
    };

    const onAnswerButtonClick = answer => {
        dispatch(setQuizAnswer(answer));
        cancelTimer();
    };

    return (<>
        { hasStarted ?
            <Sequencer title="Quiz" sequence={ isRunning ? currentQuiz : intermediateSequence }
                       timer={ timer }
                       onNextSequence={ isRunning ? onAnswerButtonClick : onNextButtonClick }
                       nextButtonLabel={ isRunning ? 'Submit' : 'Next' }/> :
            <Instructions onNextButtonClick={ onNextButtonClick }/> }
    </>);
};

export default Quiz;