import { ofType } from 'redux-observable';
import { withLatestFrom, filter, map } from 'rxjs/operators';
import { mapSelect } from '@utils/rxjsHelper';
import { SET_QUIZ_ANSWER_FULFILLED } from '@redux/quiz/constants';
import { PROGRESS_STATE_QUIZ } from '@utils/progress';
import { getSessionState } from '@redux/session/selectors/getSessionState';
import { isQuizCompleted } from '@redux/quiz/selectors/isQuizCompleted';
import { fetchNextSessionState } from '@redux/session';

const trackQuizStateEpic = (action$, state$) => action$.pipe(
    ofType(SET_QUIZ_ANSWER_FULFILLED),
    withLatestFrom(state$),
    mapSelect(getSessionState, isQuizCompleted),
    filter(([sessionState, isCompleted]) => sessionState === PROGRESS_STATE_QUIZ && isCompleted),
    map(() => fetchNextSessionState())
);

export default trackQuizStateEpic;