import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({ onClick, label, outlined, large, active, children, ...props }) => (
    <button type="button"
            onClick={ onClick }
            className={ classNames('button', { outlined, large, active }) } { ...props }>
        { label || children }
    </button>
);

Button.propTypes = {
    label: PropTypes.string,
    outlined: PropTypes.bool,
    large: PropTypes.bool,
    active: PropTypes.bool,
    onClick: PropTypes.func
};

export default Button;